{
  "name": "@dotlottie/player-component",
  "version": "2.7.3",
  "description": "dotLottie animation player web component.",
  "repository": "https://github.com/dotlottie/player-component.git",
  "homepage": "https://dotlottie.com/players",
  "bugs": "https://github.com/dotlottie/player-component/issues",
  "author": "Jawish Hameed <jawish@lottiefiles.com>",
  "license": "MIT",
  "main": "dist/dotlottie-player.js",
  "module": "dist/dotlottie-player.mjs",
  "types": "dist/dotlottie-player.d.ts",
  "files": [
    "dist"
  ],
  "keywords": [
    "dotlottie",
    "animation",
    "web component",
    "component",
    "lit-element",
    "player"
  ],
  "scripts": {
    "build": "tsup",
    "cypress:open": "cypress open --component",
    "dev": "tsup --watch",
    "lint": "eslint .",
    "lint:fix": "eslint --fix",
    "test": "cypress run --component",
    "type-check": "tsc --noEmit"
  },
  "dependencies": {
    "@dotlottie/common": "workspace:*",
    "lit": "^2.7.5"
  },
  "devDependencies": {
    "@vitejs/plugin-legacy": "^4.1.0",
    "axe-core": "^4.7.2",
    "cypress": "^12.11.0",
    "cypress-axe": "^1.4.0",
    "cypress-ct-lit": "^0.3.2",
    "lottie-web": "^5.12.2",
    "terser": "^5.19.0",
    "tsup": "^7.2.0",
    "typescript": "^4.7.4",
    "vite": "^4.3.9"
  },
  "publishConfig": {
    "access": "public"
  },
  "browserslist": [
    "> 3%"
  ]
}
